<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm>
								<CRow>
									<CCol sm="12">
										<h1>Registra tu cuenta</h1>
										<p class="text-muted">Crea tu cuenta de usuario en MetaV</p>
									</CCol>
								</CRow>
								<CRow>
									<CCol sm="12" md="6">
										<CInput
											v-model="newUser.nombre"
											:label="$t('name')"
											:placeholder="$t('enterName')"
											horizontal
											required
										/>
										<CInput
											v-model="newUser.apellido"
											:label="$t('lastName')"
											:placeholder="$t('enterLastname')"
											horizontal
											required
										/>
										<CSelect
											:value.sync="newUser.sexo"
											:label="$t('gender')"
											horizontal
											:options="[{label: $t('female'), value: 1}, {label: $t('male'), value: 2}]"
											:reduce="data => data.value"
											required
											:placeholder="$t('selectGender')"
										/>
										<CInput
											v-model="newUser.nacimiento"
											:label="$t('birth')"
											type="date"
											:min="setDate(1)"
											:max="setDate(2)"
											:description="$t('birthDescription')"
											horizontal
											required
										/>
										<CInput
											v-model="newUser.email"
											:label="$t('email')"
											:placeholder="$t('enterEmail')"
											type="email"
											horizontal
											required
										/>
									</CCol>
									<CCol>
										<CInput
											v-model="newUser.rut"
											label="Rut"
											description="Rut (Sin puntos ni guión). Ej. 11111111K"
											placeholder="Rut (Sin puntos ni guión)"
											horizontal
											required
										/>
										<CInput
											v-model="newUser.telefono"
											:label="$t('phone')"
											:placeholder="$t('enterPhone')"
											horizontal
											:prepend="phonecode"
											required
										/>
										<CInput
											v-model="newUser.direccion"
											:label="$t('address')"
											:placeholder="$t('enterAddress')"
											horizontal
											required
										/>
										<div role="group" class="form-group form-row">
											<label class="col-form-label col-sm-3"> {{$t('country')}} </label>
											<div class="col-sm-9">
												<v-select
													:placeholder="$t('selectCountry')"
													:options="$store.state.countries"
													label="name"
													:clearable="false"
													:reduce="data => data.name"
													@option:selected="(country) => {
														phonecode = '+' + country.phonecode
														getStates(country.id)
														newUser.region = null
														newUser.comuna = null
													}"
													required
													v-model="newUser.pais"></v-select>
											</div>
										</div>
										<div role="group" class="form-group form-row">
											<label class="col-form-label col-sm-3"> {{$t('region')}} </label>
											<div class="col-sm-9">
												<v-select
													:placeholder="$t('selectRegion')"
													:options="$store.state.states"
													label="name"
													:clearable="false"
													:reduce="data => data.name"
													@option:selected="(state) => {
														getComunas(state.id)
														newUser.comuna = null
													}"
													v-model="newUser.region"></v-select>
											</div>
										</div>
										<div role="group" class="form-group form-row">
											<label class="col-form-label col-sm-3"> {{$t('comuna')}} </label>
											<div class="col-sm-9">
												<v-select
													:placeholder="$t('selectComuna')"
													:options="$store.state.comunas"
													label="name"
													:clearable="false"
													:reduce="data => data.name"
													taggable
													:createOption="option => ({name:option})"
													v-model="newUser.comuna"></v-select>
											</div>
										</div>
									</CCol>
								</CRow>
								<div>
									<CInputCheckbox
										label=""
										:value="acceptedConf"
										name="terms"
										required
										:checked.sync="acceptedConf"
									/>
									<p class="ml-4">Acepto la <a href="/terminos/clausula_confidencialidad.pdf" target="_blank">cláusula de confidencialidad <CIcon name="cil-link"/></a></p>
								</div>
								<div>
									<CInputCheckbox
										label=""
										:value="acceptedDatos"
										name="terms"
										required
										:checked.sync="acceptedDatos"
									/>
									<p class="ml-4">Acepto la <a href="/terminos/clausula_tratamiento_datos_personales.pdf" target="_blank">cláusula de tratamiento de datos personales <CIcon name="cil-link"/></a></a></p>
								</div>
								<hr/>
								<CRow>
									<CCol sm="12" md="6">
										<p class="mb-1 text-muted">{{$t('password')}}</p>
										<CInput
											v-model="newUser.password"
											type="password"
											:placeholder="$t('password')"
											horizontal
											required
										/>
									</CCol>
									<CCol sm="12" md="6">
										<p class="mb-1 text-muted">{{$t('confirmPassword')}}</p>
										<CInput
											v-model="confirm_password"
											type="password"
											:placeholder="$t('confirmPassword')"
											horizontal
											required
										/>
									</CCol>
								</CRow>
								<hr/>
								<div class="d-flex align-items-center justify-content-end">
									<CBadge v-if="showRequiredAlert" color="info" class="mx-2 px-2 py-1 text-white"><CIcon name="cil-bell"/> Todos los campos son obligatorios por favor revisa los datos ingresados</CBadge>
									<CButton color="primary" class="mx-2"
										variant="outline"
										:disabled="loading"
										@click="$router.push({ name: 'Login' })">Volver</CButton>
									<CButton color="primary"
										:disabled="loading"
										@click="sendForm">Crear Cuenta</CButton>
								</div>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { createUser } from '@/helpers/api/user'
import {required, email, minDate} from '@/helpers/validators'
import { clean, validate } from 'rut.js'
import {getCountries, getStates, getComunas} from '@/helpers/api/regionalization.js'
import moment from 'moment'

export default {
  name: 'Register',
  data () {
    return {
      needValidation: false,
      showRequiredAlert: false,
      loading: false,
			acceptedConf: false,
			acceptedDatos: false,
      phonecode: '',
      newUser: {
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
				rut: '',
        nacimiento: null,
        sexo: 1,
        img_url: 'defecto.png',
        direccion: '',
        pais: null,
        region: null,
        comuna: null,
				password: ''
      },
			confirm_password: ''
    }
  },
	methods: {
    required, email,
    async getCountries(){
      const countries = await getCountries()
      if(countries.countries){
        this.$store.commit('setCountries', countries.countries)
      }
    },
    async getStates(country_id){
      const states = await getStates({country_id})
      if(states.states){
        this.$store.commit('setStates', states.states)
      }
    },
    async getComunas(state_id){
      const comunas = await getComunas({state_id})
      if(comunas.cities){
        this.$store.commit('setComunas', comunas.cities)
      }
    },
    setDate(type){
      let date = new Date()
      if(type == 1){
        date.setFullYear( date.getFullYear() - 100 )
      }
      else{
        date.setFullYear( date.getFullYear() - 13 )
      }
      return date.toISOString().split("T")[0]
    },
		isValidForm(){
      this.needValidation = true
      const validation =  Object.keys(this.newUser).every((key) => {
        return !!this.newUser[key]
      })
			if(!validation) return false

			let stopIt = false
			const age = moment().diff(moment(this.newUser.nacimiento, 'YYYY-MM-DD'), 'years')
			if(age < 13){
				this.$store.commit('addToast', {
					color: 'warning',
					header: 'Error',
					message: 'La edad debe ser mayor a 13 años'
				})
				stopIt = true
			}
			if(!this.acceptedConf || !this.acceptedDatos){
				this.$store.commit('addToast', {
					color: 'warning',
					header: 'Error',
					message: 'Debes aceptar las cláusulas antes de crear tu cuenta'
				})
				stopIt = true
			}
			if(!validate(this.newUser.rut)){
				this.$store.commit('addToast', {
					color: 'warning',
					header: 'Error',
					message: 'El rut ingresado no es válido'
				})
				stopIt = true
			}
			if(this.newUser.telefono.length < 8 || this.newUser.telefono.length > 10){
				this.$store.commit('addToast', {
					color: 'warning',
					header: 'Error',
					message: 'El teléfono debe tener entre 8 y 10 dígitos'
				})
				stopIt = true
			}
			if(this.newUser.password.length < 8){
				this.$store.commit('addToast', {
					color: 'warning',
					header: 'Error',
					message: 'La contraseña debe tener al menos 8 carácteres'
				})
				stopIt = true
			}

			if(stopIt) return false
			return true
		},
		async sendForm(){
			if(!this.isValidForm()){
				this.showRequiredAlert = true
				return false
			}
			if(this.newUser.password !== this.confirm_password){
				this.$store.commit('addToast', {
					color: 'warning',
					header: 'Error',
					message: this.$i18n.t('passwordDoNotMatch')
				})
				return false
			}

			this.loading = true
			let newUserInfo = {...this.newUser}
			newUserInfo.rut = clean(newUserInfo.rut)
			newUserInfo.telefono = this.phonecode + newUserInfo.telefono
			const data = {
				...newUserInfo
			}
			const new_user = await createUser(data)
			this.loading = false
			if(new_user.status == 201){
				this.$store.commit('addToast', {
					color: 'success',
					header: 'Usuario creado con éxito',
					message: 'Se ha creado correctamente el usuario, por favor ingresa con tu correo y contraseña'
				})
				this.$router.push({ name: 'Login' })
			}
			else{
				this.$store.commit('addToast', {
					color: 'warning',
					header: 'Error',
					message:'Ha ocurrido un error al crear al usuario, por favor intenta nuevamente'
				})
			}
		}
	}
}
</script>